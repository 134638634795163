import styled from "styled-components"

import { PlanDataTable } from "src/components/Account/BillingPortal/PlanDataTable"
import { useFetchUserHomeTokens } from "src/data/homes/queries/homeTokenQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useHomeTokens } from "src/hooks/useHomeTokens"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import InfoFilled from "src/ui/icons/important-filled.svg"
import { MInfo } from "src/ui/Info/MInfo"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function CreditBalanceSection({ credits }: { credits: string }) {
  const { t, langKeys } = useTranslate()
  const { hasHomeTokenRole } = useHomeTokens()
  const user = useGetUser()
  const fetchUserHomeTokens = useFetchUserHomeTokens({
    userId: user.user_id,
    filter: { activated: false },
    options: { enabled: hasHomeTokenRole },
  })

  const homeTokensCount = fetchUserHomeTokens.data?.paging.total_count || 0

  return (
    <Box>
      <MText variant="heading3">
        {t(langKeys.subscription_account_balance_title)}
      </MText>

      <PlanDataTable
        planData={[
          {
            key: "prepaid-homes",
            title: (
              <InfoTitle>
                {t(langKeys.prepaid_homes_text)}{" "}
                <MInfo
                  content={t(
                    langKeys.subscription_available_prepaid_homes_description
                  )}
                />
              </InfoTitle>
            ),
            value: (
              <AvailableHomeTokens
                quantity={homeTokensCount}
                loading={fetchUserHomeTokens.isInitialLoading}
                error={fetchUserHomeTokens.isError}
              />
            ),
            skip: !hasHomeTokenRole,
          },
          {
            key: "credit-balance",
            title: (
              <InfoTitle>
                {t(langKeys.subscription_credit_balance)}{" "}
                <MInfo
                  content={t(langKeys.subscription_credit_balance_description)}
                />
              </InfoTitle>
            ),
            value: <MText variant="body">{credits}</MText>,
          },
        ]}
      />
    </Box>
  )
}

function AvailableHomeTokens({
  quantity,
  loading,
  error,
}: {
  quantity: number
  loading: boolean
  error: boolean
}) {
  const { t, langKeys } = useTranslate()

  if (loading) {
    return (
      <ErrorBox>
        <MSkeleton
          variant="text"
          width={30}
          height={30}
          style={{ backgroundColor: mColors.neutralDark }}
        />
      </ErrorBox>
    )
  }

  if (error) {
    return (
      <ErrorBox>
        <InfoFilled fill={mColors.systemError} width={20} />
        <MText color="emergency">{t(langKeys.failed_to_load)}</MText>
      </ErrorBox>
    )
  }

  return <div>{quantity}</div>
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.S};
`

const ErrorBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
  justify-content: flex-end;
`

const InfoTitle = styled.div`
  display: inline-flex;
  gap: ${spacing.XS};
`
