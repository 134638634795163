import { useState } from "react"
import styled from "styled-components"

import { ReplacePaymentMethod } from "src/components/Account/BillingPortal/PaymentMethod/ReplacePaymentMethod"
import { ICustomer } from "src/components/Account/types"
import {
  useFetchAvailablePlansForUser,
  useFetchPaymentMethod,
} from "src/data/billing/queries/billingQueries"
import { CurrentPlanStatus } from "src/data/billing/types/billingTypes"
import { useFetchNonPaidInvoices } from "src/data/invoices/queries/InvoiceQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { AddButton } from "src/ui/Button/AddButton"
import { TextButton } from "src/ui/Button/TextButton"
import { backgroundGrayV2 } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

import { AddPaymentMethod } from "./AddPaymentMethod"
import { CardImitation } from "./CardImitation"
import { PaymentMethodLoading } from "./PaymentMethodLoading"
import { RemoveCardDialog } from "./RemoveCardDialog"

export function PaymentMethod({
  customer,
  userId,
}: {
  customer: ICustomer
  userId: string
}) {
  const { t } = useTranslate()

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [addPaymentMethod, setAddPaymentMethod] = useState(false)
  /**
   * Be careful adding react query fetches here, since the Stripe form is an
   * Iframe we will refech everytime we click out of that frame. The effects of
   * this will mean that the user cannot save the card details since the form
   * will alwasy reload when you exit it. This is mainly a problem when we
   * depend on the loading state of said query.
   */
  const fetchPaymentMethod = useFetchPaymentMethod({
    paymentSourceId: customer.primary_payment_source_id,
    options: {
      enabled: !!customer.primary_payment_source_id,
    },
  })

  // replace with current plan and also check if the user has no plan
  // Card should only be removable if:
  // * (User has no subscription OR subscription is cancelled) AND
  // * User has no unpaid invoices
  const fetchAvailablePlansForUser = useFetchAvailablePlansForUser({
    userId,
  })
  const fetchNonPaidInvoices = useFetchNonPaidInvoices()

  const isLoading =
    fetchPaymentMethod.isInitialLoading ||
    fetchAvailablePlansForUser.isInitialLoading ||
    fetchNonPaidInvoices.isInitialLoading

  const paymentMethod = fetchPaymentMethod.data
  const hasNoPlan = !fetchAvailablePlansForUser.data?.current_plan
  const planIsCancelled =
    fetchAvailablePlansForUser.data?.current_plan?.status ===
    CurrentPlanStatus.CANCELLED
  const hasUnpaidInvoices = (fetchNonPaidInvoices.data?.list.length ?? 0) > 0
  const cardIsRemovable = (hasNoPlan || planIsCancelled) && !hasUnpaidInvoices
  const replaceCard = !cardIsRemovable

  if (isLoading) {
    return <PaymentMethodLoading />
  }

  if (!paymentMethod) {
    if (!addPaymentMethod) {
      return (
        <NoCardBox>
          <AddButton onClick={() => setAddPaymentMethod(true)}>
            {t(langKeys.payment_method_add)}
          </AddButton>
        </NoCardBox>
      )
    } else {
      return <AddPaymentMethod customer={customer} />
    }
  }

  return (
    <div>
      <CardImitation paymentMethod={paymentMethod} />

      <div style={{ display: "flex", flexDirection: "column" }}>
        {replaceCard ? (
          <ReplacePaymentMethod customer={customer} />
        ) : (
          <TextButton
            disabled={replaceCard}
            onClick={() => setConfirmDelete(true)}
            style={{ marginTop: spacing.S, alignSelf: "flex-end" }}
          >
            {t(langKeys.remove)}
          </TextButton>
        )}
      </div>

      <RemoveCardDialog
        paymentSourceId={paymentMethod.id}
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
      />
    </div>
  )
}

const NoCardBox = styled.div`
  padding: ${spacing.L};
  background: ${backgroundGrayV2};
  min-height: 130px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`
