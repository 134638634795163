import { SubscriptionStatus } from "src/components/Account/types"
import { usePostRemoveScheduledCancellation } from "src/data/billing/queries/billingQueries"
import { TCurrentSubscription } from "src/data/billing/types/billingTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { ConfirmDialog } from "src/ui/Dialog/ConfirmDialog"

export function UndoCancelDialog({
  subscription,
  open = false,
  onClose,
}: {
  subscription: TCurrentSubscription
  open: boolean
  onClose: () => void
}) {
  const { t } = useTranslate()
  const postRemoveScheduledCancellation = usePostRemoveScheduledCancellation()

  async function undoCancel(subscriptionId?: string) {
    if (subscriptionId) {
      postRemoveScheduledCancellation.mutate(
        {
          subscriptionId,
        },
        {
          onSuccess: onClose,
          // eslint-disable-next-line no-console
          onError: console.error,
        }
      )
    }
  }

  if (subscription?.subscription_status !== SubscriptionStatus.NON_RENEWING) {
    return (
      <ConfirmDialog
        loading={postRemoveScheduledCancellation.isLoading}
        title={t(langKeys.subscription_remove_scheduled_cancellation)}
        open={open}
        onClose={onClose}
        confirmLabel={t(langKeys.close)}
        error={t(langKeys.failed_something_went_wrong)}
        onConfirm={onClose}
      />
    )
  }

  const error = postRemoveScheduledCancellation.error
    ? t(langKeys.failed_to_update)
    : null

  return (
    <ConfirmDialog
      title={t(langKeys.subscription_remove_scheduled_cancellation)}
      description={t(langKeys.subscription_remove_scheduled_cancellation_body)}
      open={open}
      onClose={onClose}
      onConfirm={() => undoCancel(subscription?.subscription_id)}
      error={error}
      loading={postRemoveScheduledCancellation.isLoading}
      confirmLabel={t(langKeys.subscription_remove_scheduled_cancellation)}
    />
  )
}
