import { BillingHistoryPaymentNote } from "src/components/Account/BillingPortal/BillingHistory/BillingHistoryPaymentNote"
import { BillingHistoryPlanRenewalNote } from "src/components/Account/BillingPortal/BillingHistory/BillingHistoryPlanRenewalNote"
import {
  TBillingHistoryEvent,
  TBillingHistoryPaymentEventMetadata,
} from "src/data/billing/types/billingTypes"
import { MText } from "src/ui/MText"

export function BillingHistoryNote({ event }: { event: TBillingHistoryEvent }) {
  const note = event.user_representation.note

  if (event.event_type === "subscription_renewed") {
    return <BillingHistoryPlanRenewalNote eventId={event.event_id} />
  }

  if (isInvoicePaidEvent(event)) {
    if (!event.metadata?.invoice_id) {
      return null
    }

    return <BillingHistoryPaymentNote invoiceId={event.metadata.invoice_id} />
  }

  return <MText variant="bodyS">{note}</MText>
}

// We need to extend `metadata` due to the API types not utilizing discriminated unions
function isInvoicePaidEvent(
  event: TBillingHistoryEvent
): event is TBillingHistoryEvent & {
  metadata?: TBillingHistoryPaymentEventMetadata
} {
  if (event.event_type === "invoice_paid") {
    return true
  }

  return false
}
