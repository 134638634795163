import { useState } from "react"
import styled from "styled-components"

import { PlanDataTable } from "src/components/Account/BillingPortal/PlanDataTable"
import {
  debugAddons,
  getAddonTotalCost,
} from "src/components/Account/BillingPortal/YourPlan/addonUtil"
import { CreditBalanceSection } from "src/components/Account/BillingPortal/YourPlan/CreditBalanceSection"
import { NoPlan } from "src/components/Account/BillingPortal/YourPlan/NoPlan"
import { PlanRenewal } from "src/components/Account/BillingPortal/YourPlan/PlanRenewal"
import { UndoCancelDialog } from "src/components/Account/BillingPortal/YourPlan/UndoCancelDialog"
import { UndoChangeDialog } from "src/components/Account/BillingPortal/YourPlan/UndoChangeDialog"
import { YourPlanAddonsRow } from "src/components/Account/BillingPortal/YourPlan/YourPlanAddonsRow"
import { YourPlanAlerts } from "src/components/Account/BillingPortal/YourPlan/YourPlanAlerts"
import { YourPlanLoading } from "src/components/Account/BillingPortal/YourPlan/YourPlanLoading"
import {
  ICustomerBalance,
  SubscriptionStatus,
  Taxability,
} from "src/components/Account/types"
import { useAppData } from "src/context/useAppData"
import {
  TCurrentSubscription,
  TSubscriptionAddon,
  TSubscriptionCurrencyCode,
} from "src/data/billing/types/billingTypes"
import { useFetchResponseServiceAddons } from "src/data/homes/queries/responseServiceQueries"
import { IResponseServiceCalloutEstimates } from "src/data/homes/types/responseServiceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useDunning } from "src/data/user/logic/dunningLogic"
import {
  getPlanDescriptionKey,
  translateBillingPeriod,
  translatePlanName,
} from "src/data/user/logic/userPlan"
import { DUNNING_STAGE, PLAN, TUser } from "src/data/user/user"
import { useAppLocation } from "src/hooks/useAppLocation"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import CouponIcon from "src/ui/icons/coupon.svg"
import { MInfo } from "src/ui/Info/MInfo"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MBanner } from "src/ui/MBanner/MBanner"
import { BodyMixin, MText, SubtitleMixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatPrice } from "src/utils/formatPrice"

import { CancelPlanDialog } from "./CancelPlanDialog"
export function YourPlan({
  balances,
  taxability,
  hasCSM,
}: {
  balances: ICustomerBalance[] | undefined
  taxability: Taxability | undefined
  hasCSM: boolean
}) {
  const { useMockData } = useFlags()
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const user = useGetUser()
  const location = useAppLocation()
  const { orgAccessLogic } = useOrganization()

  const [showUndoCancelDialog, setShowUndoCancelDialog] = useState(false)
  const [showUndoChangeDialog, setShowUndoChangeDialog] = useState(false)
  const calloutEstimateQuery = useFetchResponseServiceAddons()

  /*
    In cases where the subscription is invalid we want to show a banner,
    therefore we need to get the query of the current subscription
  */
  const { currentSubscription, fetchCurrentSubscription } = useAppData()
  const dunningData = useDunning()

  const openCancelPlan =
    location.pathname === Routes.CancelPlan.location().pathname

  const isOrgOwner = orgAccessLogic.hasOwnerAccess

  if (calloutEstimateQuery.isLoading) {
    return <YourPlanLoading />
  }

  const plan = currentSubscription?.plan
    ? convertToPlan(
        currentSubscription?.plan,
        currentSubscription?.subscription_status
      )
    : user.current_plan

  const planName = translatePlanName(t, plan, currentSubscription?.legacy_plan)

  const subscriptionAddons: TCurrentSubscription["addons"] = [
    ...(currentSubscription?.addons ?? []),
    ...(useMockData ? debugAddons : []),
  ]

  const responseServiceAddon = currentSubscription
    ? getResponseServiceAddon(subscriptionAddons, calloutEstimateQuery.data)
    : null

  if (
    fetchCurrentSubscription?.error &&
    fetchCurrentSubscription.error.response?.data?.error_key ===
      "invalid_subscription_state"
  ) {
    return (
      <MBanner type="error" fullWidth>
        {`${t(langKeys.failed_general_error_title)}. ${t(
          langKeys.failed_contact_support_to_resolve
        )}`}
      </MBanner>
    )
  }

  if (plan === PLAN.starter) {
    return (
      <div>
        <NoPlan />

        <ButtonRow>
          <MButton onClick={() => navigate(Routes.ChangePlan.location())}>
            {t(langKeys.subscription_no_plan_title)}
          </MButton>
        </ButtonRow>

        <TableSection>
          <CreditBalanceSection
            credits={
              getTotalCredits(balances, currentSubscription?.currency_code) ??
              ""
            }
          />
        </TableSection>
      </div>
    )
  }

  const planDescriptionKey = getPlanDescriptionKey(
    currentSubscription ?? undefined
  )
  const planDescriptionMoreInfo = currentSubscription?.legacy_plan
    ? null
    : t(langKeys.subscription_description_more_info)
  function PlanDescription() {
    if (!!currentSubscription?.custom_plan) return null

    return (
      <MText variant="body" color="secondary">
        {planDescriptionKey && t(planDescriptionKey)}
        {planDescriptionMoreInfo && ` ${planDescriptionMoreInfo}`}{" "}
        <ExternalLink
          href="https://minut.com/pricing"
          style={{ whiteSpace: "pre" }}
        >
          {t(langKeys.learn_more)}
        </ExternalLink>
      </MText>
    )
  }

  const planDataHidden = currentSubscription?.subscription_status === "future"

  const showPlanRenewal =
    currentSubscription?.subscription_status === SubscriptionStatus.ACTIVE &&
    currentSubscription.billing_model === "plan_unit_per_home"

  return (
    <div>
      {currentSubscription && (
        <YourPlanAlerts
          subscription={currentSubscription}
          setShowUndoCancelDialog={setShowUndoCancelDialog}
          setShowUndoChangeDialog={setShowUndoChangeDialog}
        />
      )}
      <PlanInfo>
        <MText variant="subtitleS">{t(langKeys.your_plan)}</MText>
        <PlanTitle>
          <MText variant="heading2">{planName}</MText>
          {currentSubscription?.custom_plan && (
            <MBadge>{t("badge_custom_plan")}</MBadge>
          )}
          {dunningData.stage === DUNNING_STAGE.DUNNING_SUSPENDED && (
            <MBadge color="error">{t(langKeys.dunning_suspended_pill)}</MBadge>
          )}
        </PlanTitle>
        <PlanDescription />
      </PlanInfo>

      {showPlanRenewal && (
        <PlanRenewalEstimateWrapper>
          <PlanRenewal
            userId={user.user_id}
            date={currentSubscription?.next_billing_at}
          />
        </PlanRenewalEstimateWrapper>
      )}

      <PlanDataTable
        hidden={planDataHidden}
        planData={[
          {
            key: "plan",
            title: "Plan",
            value: (
              <PlanPrice
                plan={plan}
                subscription={currentSubscription}
                taxExempt={taxability}
              />
            ),
          },
          {
            key: "addons",
            title: null,
            value: currentSubscription && (
              <YourPlanAddonsRow
                planStatus={currentSubscription.subscription_status}
                addons={subscriptionAddons}
                currencyCode={currentSubscription.currency_code}
                billingPeriodUnit={currentSubscription.billing_period}
              />
            ),
            skip: !currentSubscription?.addons,
          },
          {
            key: "response-service",
            title: "Response service",
            value: currentSubscription ? (
              <ResponseServicePrice
                subscription={currentSubscription}
                callouts={calloutEstimateQuery.data}
              />
            ) : null,
            skip: !responseServiceAddon || true, // disabled for now until it's been decided what to do with it
          },
          {
            key: "callout",
            title: (
              <Callout>
                <div>Callout fee</div>
                <MText variant="bodyS" color="secondary">
                  You will only be billed for the callout when a responder is
                  sent.
                </MText>
              </Callout>
            ),
            value: (
              <ResponseServiceCallout
                addon={responseServiceAddon}
                callouts={calloutEstimateQuery.data}
                currencyCode={currentSubscription?.currency_code}
              />
            ),
            skip:
              !getResponseServiceCallout(
                responseServiceAddon,
                calloutEstimateQuery.data
              ) || true, // disabled for now until it's been decided what to do with it
          },
          {
            key: "unbilled-charges",
            skip: currentSubscription?.billing_model !== "plan_unit_per_home",
            title: (
              <UnbilledChargesTitle>
                {t(langKeys.subscription_unbilled_charges)}
                <MInfo
                  content={t(
                    langKeys.subscription_unbilled_charges_description
                  )}
                />
              </UnbilledChargesTitle>
            ),
            value: (
              <span>
                {getTotalUnbilledCharges(
                  balances,
                  currentSubscription?.currency_code
                )}
              </span>
            ),
          },
          {
            key: "total",
            title: t(langKeys.subscription_total_cost),
            value: (
              <TotalPrice
                plan={plan}
                subscription={currentSubscription}
                addons={subscriptionAddons}
                callouts={calloutEstimateQuery.data}
              />
            ),
          },
        ]}
      />

      <ButtonRow>
        <MButton
          onClick={() => navigate(Routes.ChangePlan.location())}
          disabled={!isOrgOwner}
        >
          {t(langKeys.subscription_change_plan)}
        </MButton>

        {currentSubscription?.subscription_status ===
          SubscriptionStatus.ACTIVE && (
          <TextButton
            onClick={() => navigate(Routes.CancelPlan.location().pathname)}
            disabled={!isOrgOwner}
          >
            {t(langKeys.cancel_plan)}
          </TextButton>
        )}
      </ButtonRow>

      <TableSection>
        <CreditBalanceSection
          credits={
            getTotalCredits(balances, currentSubscription?.currency_code) ?? ""
          }
        />
      </TableSection>

      {currentSubscription && (
        <UndoCancelDialog
          subscription={currentSubscription}
          open={showUndoCancelDialog}
          onClose={() => setShowUndoCancelDialog(false)}
        />
      )}

      <UndoChangeDialog
        open={showUndoChangeDialog}
        onClose={() => setShowUndoChangeDialog(false)}
      />

      <CancelPlanDialog open={openCancelPlan} hasCSM={hasCSM} />
    </div>
  )
}

function getResponseServiceAddon(
  addons: TCurrentSubscription["addons"],
  callouts?: IResponseServiceCalloutEstimates
): TSubscriptionAddon | null {
  if (!addons || !callouts) {
    return null
  }
  // TODO WEB-410: create a proper payment model in backend
  const addonsFiltered = addons.filter(
    (addon) => addon.id && !!callouts[addon.id]
  )

  if (addonsFiltered.length > 1) {
    throw Error("More than one response service addon")
  } else if (addonsFiltered.length < 1) {
    return null
  } else if (addonsFiltered[0]) {
    return addonsFiltered[0]
  } else {
    return null
  }
}

function getResponseServiceCallout(
  addon: TSubscriptionAddon | null,
  callouts?: IResponseServiceCalloutEstimates
) {
  if (!addon || !callouts) {
    return null
  }

  return callouts[addon.id]
}

function ResponseServiceCallout({
  addon,
  callouts,
  currencyCode,
}: {
  addon: TSubscriptionAddon | null
  callouts?: IResponseServiceCalloutEstimates
  currencyCode?: TSubscriptionCurrencyCode
}) {
  const callout = getResponseServiceCallout(addon, callouts)

  if (!callout) {
    return <></>
  }

  return (
    <Callout>
      +{formatPrice(callout?.dispatch_cost / 100, currencyCode || "USD")} /
      callout
    </Callout>
  )
}

function ResponseServicePrice({
  subscription,
  callouts,
}: {
  subscription: TCurrentSubscription
  callouts?: IResponseServiceCalloutEstimates
}) {
  const { t, langKeys } = useTranslate()

  const addon = getResponseServiceAddon(subscription.addons, callouts)
  const currencyCode = subscription?.currency_code
  const addonPrice = (addon?.amount || 0) / 100
  const addonUnitPrice = addon?.unit_price ? addon.unit_price / 100 : 0
  const billingPeriod = translateBillingPeriod(t, subscription?.billing_period)

  //this will be checked in an earlier stage, this is mainly to skip typescripts type checking
  if (!addon) {
    return <></>
  }

  return (
    <span>
      <TotalPriceLine>
        <span>
          <span>
            {formatPrice(addonPrice, currencyCode || "USD")}/
            {billingPeriod.toLocaleLowerCase()}
          </span>{" "}
          ({formatPrice(addonUnitPrice, currencyCode || "USD")} ×{" "}
          {addon?.quantity}{" "}
          {t(langKeys.home, {
            count: addon?.quantity,
          }).toLocaleLowerCase()}
          )
        </span>
      </TotalPriceLine>
    </span>
  )
}

function PlanPrice({
  plan,
  subscription,
  taxExempt,
}: {
  plan: TUser["current_plan"]
  subscription?: TCurrentSubscription | null
  taxExempt?: Taxability
}) {
  const { t, langKeys } = useTranslate()

  if (
    plan === PLAN.starter ||
    !subscription ||
    subscription?.subscription_status === SubscriptionStatus.CANCELLED
  ) {
    return <>-</>
  }

  const planPrice =
    ((subscription?.tax_exempt_plan_amount ?? subscription?.plan_amount) || 0) /
    100
  const currencyCode = subscription?.currency_code
  const billingPeriod = translateBillingPeriod(t, subscription?.billing_period)

  return (
    <PlanPriceBox>
      <PlanPriceHomes>
        <span>
          {subscription.plan_quantity}{" "}
          {t(langKeys.home, {
            count: subscription?.plan_quantity,
          }).toLocaleLowerCase()}
        </span>{" "}
        <span>
          {formatPrice(planPrice, currencyCode || "USD")}/
          {billingPeriod.toLocaleLowerCase()}
        </span>
      </PlanPriceHomes>

      {subscription?.coupons &&
        subscription.coupons.map((coupon) => (
          <MText
            variant="bodyS"
            color="secondary"
            as="span"
            key={coupon.coupon_id}
            style={{ whiteSpace: "nowrap" }}
          >
            <CouponIcon width={20} style={{ marginRight: spacing.XS2 }} />
            {coupon.coupon_id}
          </MText>
        ))}

      {taxExempt === Taxability.EXEMPT && (
        <MBanner type="info" size="small" fullWidth={false}>
          {t(langKeys.billing_tax_exempt)}
        </MBanner>
      )}
    </PlanPriceBox>
  )
}

const PlanPriceBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1ch;
`

const PlanPriceHomes = styled.div`
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
  text-align: end;
  flex-wrap: wrap;
  column-gap: ${spacing.M};
`

function TotalPrice({
  plan,
  subscription,
  addons,
  callouts,
}: {
  plan: TUser["current_plan"]
  subscription?: TCurrentSubscription | null
  addons: TSubscriptionAddon[]
  callouts?: IResponseServiceCalloutEstimates
}) {
  const { t, langKeys } = useTranslate()

  if (
    plan === PLAN.starter ||
    !subscription ||
    subscription?.subscription_status === SubscriptionStatus.CANCELLED
  ) {
    return <>{t(langKeys.subscription_free)}</>
  }

  const guardAssistAddon = getResponseServiceAddon(addons, callouts)
  const otherAddons = addons?.filter((a) => a.id !== guardAssistAddon?.id)
  const addonsFiltered = [guardAssistAddon, ...otherAddons].filter(Boolean)

  const planPrice =
    ((subscription?.tax_exempt_plan_amount ?? subscription?.plan_amount) || 0) /
    100
  const addonPrice = getAddonTotalCost(addonsFiltered)
  const totalPrice = planPrice + addonPrice

  const currencyCode = subscription?.currency_code
  const billingPeriod = translateBillingPeriod(
    t,
    subscription?.billing_period
  ).toLocaleLowerCase()

  return (
    <TotalPriceLine>
      <Money>
        {formatPrice(totalPrice, currencyCode || "USD")}/{billingPeriod}
      </Money>
    </TotalPriceLine>
  )
}

function getTotalUnbilledCharges(
  balances: ICustomerBalance[] | undefined,
  currencyCode: TSubscriptionCurrencyCode | undefined
) {
  if (!balances) {
    return formatPrice(0, currencyCode ?? "USD")
  }

  return balances
    .map((b) => formatPrice(b.unbilled_charges / 100, b.currency_code))
    .join(" | ")
}

function getTotalCredits(
  balances: ICustomerBalance[] | undefined,
  currencyCode: TSubscriptionCurrencyCode | undefined
) {
  if (!balances) {
    return formatPrice(0, currencyCode ?? "USD")
  }

  return balances
    .map((b) => {
      const promotional = b.promotional_credits / 100
      const refundable = b.refundable_credits / 100
      const excessPayments = b.excess_payments / 100
      const total = promotional + refundable + excessPayments
      return formatPrice(total, b.currency_code)
    })
    .join(" | ")
}

function convertToPlan(
  plan: TCurrentSubscription["plan"] | undefined,
  status: TCurrentSubscription["subscription_status"] | undefined
) {
  if (status === "cancelled") {
    return PLAN.starter
  }

  if (plan === "pro") {
    return PLAN.pro
  }

  if (plan === "pro_plus") {
    return PLAN.pro_plus
  }

  if (plan === "standard") {
    return PLAN.standard
  }

  return PLAN.starter
}

const PlanInfo = styled.div`
  margin-bottom: ${spacing.L};
`

const PlanTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.S};

  &::first-letter {
    text-transform: capitalize;
  }
`

const TotalPriceLine = styled.div`
  & > *:not(:last-child) {
    margin-right: 1ch;
  }
`

const TableSection = styled.div`
  margin-top: ${spacing.XL4};
`

const Callout = styled.div`
  ${BodyMixin};
  margin-top: ${spacing.M};
`

const Money = styled.span`
  ${SubtitleMixin};
`

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${spacing.M};
  margin-top: ${spacing.XL2};
`

const UnbilledChargesTitle = styled.div`
  display: inline-flex;
  gap: ${spacing.XS};
`

const PlanRenewalEstimateWrapper = styled.div`
  margin-bottom: ${spacing.L};
`
